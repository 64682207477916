import cx from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { useMediaQuery } from 'hooks/use-media-query.hook';

import css from './tooltip.module.scss';

type Props = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
  classNameContent?: string;
  mobileHide?: boolean;
  width?: number;
  align?: 'top' | 'bottom' | 'right' | 'left';
};

export const Tooltip = ({
  children,
  content,
  className,
  classNameContent,
  mobileHide,
  width = 180,
  align = 'top',
}: Props) => {
  const refSetTimeout = useRef<NodeJS.Timeout>();

  const [showToolTip, setShowToolTip] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const root = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 430px)');

  const onMouseEnterHandler = () => {
    refSetTimeout.current = setTimeout(() => {
      setShowToolTip(true);
    }, 200);
  };

  const onMouseLeaveHandler = () => {
    clearTimeout(refSetTimeout.current);
    setShowToolTip(false);
  };

  useEffect(() => {
    if (showToolTip) {
      if (ref.current && root.current) {
        const boundary = ref.current.getBoundingClientRect();
        const rBoundary = root.current.getBoundingClientRect();

        if (boundary.width + boundary.x) {
          // ref.current.style.right = '0px';
          // ref.current.style.left = 'inherit';
          // ref.current.style.transform = 'none';
        }
      }
    }
  }, [showToolTip]);

  if (mobileHide && isMobile) {
    return <>{children}</>;
  }

  return (
    <div
      className={cx(css.container, className)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={() => setShowToolTip((prev) => !prev)}
      data-state={showToolTip ? 'open' : 'closed'}
      ref={root}
    >
      {children}
      <div
        className={cx(
          css.tooltip,
          css[align],
          { [css.show]: showToolTip },
          classNameContent
        )}
        style={{ width }}
        ref={ref}
      >
        {/* <span ref={arrow} className={cx(css.arrow, css[align])} /> */}
        {content}
      </div>
    </div>
  );
};
